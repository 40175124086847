































import { Component, Vue, Watch } from 'vue-property-decorator';
import MonthPicker from '@/components/form/MonthPicker.vue';
import { format, subYears } from '@/filter/dateFormat';
import merge from 'lodash.merge';
import { darkTheme } from '@/plugins/highcharts';

interface Options {
  chart: { type: string };
  title: { text: string };
  tooltip: { crosshairs: boolean, shared: boolean };
  plotOptions: { line: { dataLabels: { enabled: boolean } } };
  xAxis: { categories: string[], labels: { rotation: number } };
  yAxis: { min: number, title: { text: string } };
  series: Array<{ name: string, data: { y: number, formatted: string } }>;
  colors: string[];
}

@Component({ components: { MonthPicker } })
export default class Conservator extends Vue {
  waiting: boolean = false;

  end: string = format(Date.now(), 'yyyy-MM');
  start: string = format(subYears(Date.now(), 1), 'yyyy-MM');

  options: Options = {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Statistik: Betreuer Verteilung'
    },
    tooltip: {
      crosshairs: true,
      shared: true
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true
        }
      }
    },
    xAxis: {
      categories: [],
      labels: {
        rotation: -45
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Anzahl Betreuungen'
      }
    },
    series: [],
    colors: ['#b71c1c', '#92c3ff', '#ff9c43', '#3fdd7c', '#9b1fdd', '#d97bdd']
  };

  get allOptions() {
    if (!this.$vuetify.theme.dark) {
      return this.options;
    }

    return merge(darkTheme(), this.options)
  }

  validEnd(value: string): boolean {
    return this.start < value && format(Date.now(), 'yyyy-MM') >= value;
  }

  validStart(value: string): boolean {
    return this.end > value;
  }

  @Watch('start')
  onStart() {
    return this.fetch();
  }

  @Watch('end')
  onEnd() {
    return this.fetch();
  }

  created() {
    return this.fetch();
  }

  async fetch() {
    const response = await this.$http.get('api/statistic/conservator', {
      responseType: 'json',
      params: { start: `${this.start}-01`, end: `${this.end}-01` }
    });

    this.options.series = [];
    this.options.xAxis.categories = [];

    const { months, ...conservators } = response.data;

    this.options.xAxis.categories = months.map((month: string) => format(month, 'MMMM yyyy'));

    for (const conservator in conservators) {
      if (!conservators.hasOwnProperty(conservator)) continue;

      this.options.series.push({
        name: conservator.split('/')[0],
        data: conservators[conservator]
      });
    }

    setTimeout(() => {
      this.waiting = true;
    }, 500);
  }
}
